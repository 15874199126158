


























































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {AppInfo} from '@/models/AppInfo';
import {FreigthListInfo} from '@/models/FreigthListInfo';
import {FileTransferType} from '@/enums/FileTransferType';
import {FreightTypesRequestLookup} from '@/enums/FreightTypes';
import Tracker from '@/components/freight/Tracker.vue';
import DownloadButton from '@/components/commons/DownloadButton.vue';
import FreightRowAction from '@/components/freight/FreightRowAction.vue';
import SelectStageFreight from '@/components/freight/SelectStageFreight.vue';
import FilterBy from '@/components/commons/FilterBy.vue';
import FilterByItem from '@/components/commons/FilterByItem.vue';
import {Range} from '@/models/Range';
import Filters from '@/utils/Filters';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import {ChaveTn3} from '@/utils/ChaveTn3';
import {DateUtils} from '@/utils/DateUtils';
import {Utils} from '@/utils/Utils';
import {Authority} from '@/models/Authority';
import {EtapaRastreamento, EtapaRastreamentoLookup} from '@/enums/EtapaRastreamento';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');
const AppInfoStore = namespace('AppInfoStore');

export class FreightFormFilters {
    public show: boolean = false;
    // cte
    public numero: string = '';
    public serie: string = '';
    public chave: string = '';

    public dataEmissao: string = '';
    public etapaRastreamento: string = '';
    public remClienteCpfCnpj: string = '';
    public remClienteRazao: string = '';
    public destClienteCpfCnpj: string = '';
    public destClienteRazao: string = '';

    // nfe
    public nfeNumero: string = '';
    public nfeSerie: string = '';
    public nfeChave: string = '';
}

@Component({
    name: 'Freight',
    components: {
        SelectStageFreight, FreightRowAction, DownloadButton, Tracker, FilterBy, FilterByItem,
        ErrorMessage,
    },
})

export default class Freight extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    public $refs!: {
        table: any,
        drawer: any,
    };

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    // drawer
    public dialog: boolean = false;

    // filter para data
    public filterBrDate = Vue.filter('brDate');

    /*
    * propriedade pra filtro de busca
    */
    public formFilters: FreightFormFilters = new FreightFormFilters();

    /*
    * largura do label
    */
    public formLabelWidth: any = '120px';

    /*
    * carrega item para a tabela
    */
    public isLoading: boolean = false;

    /*
    * quantidade de registros
    */
    public totalRecords: number = 0;

    /*
     * Altura default da tabela da listagem
     */
    public tableHeight: string = '300px';

    /*
     * Permissao para baixar o comprovante de entrega
     */
    public hasPermissaoComprovante: boolean = false;

    /*
    * parâmetros a serem distribuidos para controle de páginação
    * e exibição de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'dataEmissao',
            type: 'desc',
        },
        page: 1,
        perPage: 50,
    };

    /*
    * propriedades das colunas da tabela
    */
    public columns = [
        {
            hidden: false,
            label: 'Ref.',
            width: '30px',
            field: 'id',
            sortable: false,
            tdClass: 'text-center custom-td-class-disabled',
            thClass: 'text-center',
        },
        {
            label: 'NF-e',
            width: '50px',
            field: this.formatNfe,
            sortable: false,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'CT-e',
            width: '55px',
            field: 'numero',
            type: 'string',
            sortable: true,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Tipo CT-e',
            width: '60px',
            field: 'tipoCte',
            type: 'string',
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Remetente',
            width: '120px',
            field: 'remClienteRazao',
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
            sortable: true,
        },
        {
            label: 'Destinatário',
            width: '120px',
            field: 'destClienteRazao',
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
            sortable: true,
        },
        {
            label: 'Dt. Emissão',
            width: '60px',
            field: 'dataEmissao.value',
            type: 'date',
            tdClass: 'text-center',
            thClass: 'text-center',
            formatFn: this.formatDateFn,
            sortable: true,
        },
        {
            label: 'Prev. Entrega',
            width: '60px',
            tdClass: 'text-center',
            thClass: 'text-center',
            field: 'dataPrevistaStr',
            sortable: false,
        },
        {
            label: 'Destino',
            width: '80px',
            tdClass: 'text-left',
            thClass: 'text-left',
            field: 'cidadeDestinoNome',
            type: 'string',
            sortable: true,
        },
        {
            label: 'Situação',
            width: '105px',
            field: 'etapaRastreamento',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
        },
        {
            label: 'Opções',
            width: '145px',
            field: 'actions',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center overflow-visible',
        },
    ];

    /*
    * linhas da tebela
    */
    public rows: FreigthListInfo[] = [] as FreigthListInfo[];

    /*
    * compactação de arquivo de download
    */
    public zip: FileTransferType = FileTransferType.ZIP;

    /*
    * Exportacao para excel
    */
    public xlsx: FileTransferType = FileTransferType.EXCEL;

    /**
     * Reset filtros de pesquisa
     */
    public cleanAll(): void {
        this.formFilters.show = false;
        this.onRefreshClick();
    }

    public formatTipoCTe(rowObj): string {
        if (rowObj.tipoCte) {
            return FreightTypesRequestLookup.getStatusByValue(Number(rowObj.tipoCte)).description;
        }
        return ``;
    }

    public formatString(str, len = 50, reduce = 47): string {
        return Utils.formatSizeStringRow(str, len, reduce);
    }

    public formatNfe(row): string {
        if (row.nfeResourceList != null && row.nfeResourceList.length) {
            const nfe = row.nfeResourceList[0];
            if (nfe != null) {
                return `${nfe.numero} - ${nfe.serie}`;
            }
        }
        return ``;
    }

    public formatDateFn(param) {
        return param ? this.filterBrDate(param) : '-';
    }

    /**
     * #25103
     * Trata para mostrar a data de entrega como data prevista
     */
    public formatDateEntrega(row) {
        if (this.info.alteraDataEntrega && row.dataEntregaStr != null) {
            return row.dataEntregaStr;
        } else {
            return row.dataPrevistaStr;
        }

        return ``;
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search(): void {
        this.formFilters.show = true;
        this.$refs.drawer.closeDrawer();
        this.onPageChange('1');
    }

    /*
    * atualiza os párâmetros de paginação
    */
    public updateParams(newProps): void {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
    }

    /*
    * carrega as página com o snovos parâmetros
    */
    public onPageChange(params): void {
        this.updateParams({page: params.currentPage});
        this.loadItems();
    }

    /*
    * parâmetros por página a partir da página atual
    */
    public onPerPageChange(params): void {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
    }

    /*
    * distribui de forma descrescente os itens da colunas da página
    */
    public onSortChange(params): void {
        let paramField = '';
        if (params.length) {
            if (params[0].field.includes('.')) {
                paramField = params[0].field.split('.')[0];
            } else {
                paramField = params[0].field;
            }

            this.updateParams({
                sort: {
                    field: paramField,
                    type: params[0].type,
                },
            });
        }
        this.loadItems();
    }

    /*
    * carrega a página a partir dos dados coletados pelo ponto de acesso
    */
    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.applyFilters();
        this.$axios
            .post(
                `/cte/list/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                const data = response.data || {};
                this.totalRecords = data.recordsFiltered;
                this.rows = data.resources || [];
            }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        }).finally(() => this.isLoading = false);
    }

    /*
    *
    */
    public packData(): any {
        return {idDocList: this.$refs.table?.selectedRows.map((row) => row.id) || []};
    }

    public packDataExport(): any {
        return {
            cteResource: this.serverParams.columnFilters,
            idDocList: this.$refs.table?.selectedRows.map((row) => row.id) || [],
        };
    }

    public getFiltersToExport(): any {
        return this.serverParams;
    }

    /*
    * empacota para o Download
    */
    public onPackDownload(event: Event) {
        if (!this.packData().idDocList.length) {
            return true;
        }
        return false;
    }

    /*
    * recarrega a página
    */
    public onRefreshClick(): void {
        this.formFilters = new FreightFormFilters();
        this.serverParams = {
            columnFilters: {},
            page: 1,
            perPage: 50,
        };
        this.$refs.table.$refs.paginationBottom.currentPage = 1;
        this.$refs.table.reset();
    }

    /**
     * abre formulario de pesquisa
     */
    public openFormPesq() {
        this.setErrorMessage('');
        this.applyMask();
    }

    /**
     * Mascara de formatacao
     */
    public applyMask() {
        const im = new Inputmask('99/99/9999');
        const elDateEmissInit = document.getElementById('dtemiss-range-start')!;
        const elDateEmissEnd = document.getElementById('dtemiss-range-end')!;
        im.mask(elDateEmissInit);
        im.mask(elDateEmissEnd);
    }

    /**
     * Verifica se a chave da NFe e valida
     */
    public validaChaveNfe(param) {
        if (param == null || param === '') {
            this.setErrorMessage('');
            return;
        }

        if (ChaveTn3.isNfe(param) === false) {
            this.setErrorMessage('Chave da NF-e não é valida!');
        } else {
            this.setErrorMessage('');
        }
    }

    public validaChaveCte(param) {
        if (param == null || param === '') {
            this.setErrorMessage('');
            return;
        }
        if (ChaveTn3.isCte(param) === false) {
            this.setErrorMessage('Chave do CT-e não é valida!');
        } else {
            this.setErrorMessage('');
        }
    }

    public calcPositionTable(): void {
        if (this.$refs.table != null) {
            const tableHeader = document.getElementsByClassName('vgt-fixed-header');
            const tableFooter = document.getElementsByClassName('vgt-wrap__footer');
            const headerPos = tableHeader[0].getBoundingClientRect();
            const footerPos = tableFooter[0].getBoundingClientRect();
            const calc = footerPos.top - (headerPos.bottom - headerPos.height);
            this.tableHeight = calc.toFixed(0);
        }
    }

    public getFixedHeigthTable(): string {
        return `${this.tableHeight}px`;
    }

    /*
    * cancela o formulário de busca
    */
    public cancelForm(): void {
        this.dialog = false;
    }

    public joinDestinatarioCnpjNome(): string {
        const cnpj = this.formFilters.destClienteCpfCnpj;
        const razao = this.formFilters.destClienteRazao;
        return Utils.joinCnpjRazao(cnpj, razao);
    }

    public joinRemententeCnpjNome(): string {
        const cnpj = this.formFilters.remClienteCpfCnpj;
        const razao = this.formFilters.remClienteRazao;
        return Utils.joinCnpjRazao(cnpj, razao);
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    public setPermissaoComprovante(): void {
        this.hasPermissaoComprovante = this.hasAuthOnCurrentCompany(
            'ACESSAR_COMPROVANTE_ENTREGA',
            this.currentCompany,
        );
    }

    /*
    * aplica os filtros de busca por item da página
    */
    private applyFilters() {
        this.serverParams.columnFilters = Object.assign(
            {},
            {
                numero: this.formFilters.numero ?
                    new Range(this.formFilters.numero, this.formFilters.numero) : null,
                serie: this.formFilters.serie || null,
                chave: this.formFilters.chave || null,
                nfeNumero: this.formFilters.nfeNumero || null,
                nfeSerie: this.formFilters.nfeSerie || null,
                nfeChave: this.formFilters.nfeChave || null,
                remClienteCpfCnpj: this.formFilters.remClienteCpfCnpj || null,
                remClienteRazao: this.formFilters.remClienteRazao || null,
                destClienteCpfCnpj: this.formFilters.destClienteCpfCnpj || null,
                destClienteRazao: this.formFilters.destClienteRazao || null,
                dataEmissao: this.formFilters.dataEmissao ?
                    new Range(
                        this.formFilters.dataEmissao[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataEmissao[1] + DateUtils.getMinAndMaxTime().max) : null,
                etapaRastreamento: typeof this.formFilters.etapaRastreamento === 'number' ?
                    this.formFilters.etapaRastreamento : null,
            },
        );
    }

    private hasPermission(): boolean {
        return this.hasAuthOnCurrentCompany(this.$route.meta.permission, this.currentCompany);
    }

    private beforeMount(): void {
        if (this.isAuthenticated) {
            this.updateAuthorities();
        }
        if (!this.hasPermission()) {
            this.$router.push('dashboard');
        }
    }

    /*
     * monta a página
     */
    private mounted() {
        this.$nextTick(() => {
            this.calcPositionTable();
            this.setPermissaoComprovante();
        });
        window.addEventListener('resize', this.calcPositionTable);
    }

    private unmounted() {
        window.removeEventListener('resize', this.calcPositionTable);
    }
}
