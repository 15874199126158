










































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EtapaRastreamento, EtapaRastreamentoLookup} from '@/enums/EtapaRastreamento';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';

const AuthStore = namespace('AuthStore');

interface TrackingHistory {
    mensagem: string;
    dataOcr: string;
}

@Component({
    name: 'Tracker',
})
export default class Tracker extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public currentStage!: number;

    @Prop({default: false})
    public openDirect!: boolean;

    @Prop({default: true})
    public onShowModalDialog!: boolean;

    @Prop()
    public chaveCte!: string;

    public loading: boolean = false;

    public isShowDialog: boolean = true;

    public showDialog: boolean = false;

    public trackerHistory: TrackingHistory[] = [];

    public get stage() {
        return EtapaRastreamentoLookup.lookup[this.currentStage] || EtapaRastreamento.DEFAULT;
    }

    public get activeStage() {
        return this.currentStage + 1;
    }

    public tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 0) {
            return 'primary-table-row';
        } else {
            return 'secondary-table-row';
        }
        return '';
    }

    public onClick(): void {
        this.loading = true;
        this.$axios
            .get(`/cte/tracking/${this.currentCompany.id}/${this.chaveCte}`)
            .then((response) => {
                this.trackerHistory = response.data || [];
                this.showDialog = true;
                this.loading = false;
            }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        });
    }

    @Emit('on-close')
    public onClose() {
    }

    private mounted() {
        if (this.openDirect) {
            this.onClick();
        }
    }
}
