
export class ChaveTn3 {

    public static modelos = {
        MODELO_CTE: '57',
        MODELO_NFE: '55',
        MODELO_MDFE: '58',
        MODELO_NFC: '65',
    };

    public static extraiModelo(chave: string): string {
        let modelo = '';
        if (chave != null && chave.length === 44) {
            modelo = chave.substring(20, 22);
        }
        return modelo;
    }

    public static isNfe(chave: string): boolean {
        if (this.extraiModelo(chave) !== this.modelos.MODELO_NFE) {
            return false;
        }
        return true;
    }

    public static isCte(chave: string): boolean {
        if (this.extraiModelo(chave) !== this.modelos.MODELO_CTE) {
            return false;
        }
        return true;
    }
}
