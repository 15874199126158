














































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/types/button';
import {ElementUIComponentSize} from '@/types/component';
import {AxiosRequestConfig} from 'axios';

@Component({
    name: 'DropdownButton',
})

export default class DropdownButton extends Vue {

    @Prop()
    public readonly filters!: any;

    @Prop({default: false})
    public readonly disabled!: boolean;

    @Prop({default: 'primary'})
    public readonly type!: ButtonType;

    @Prop({default: 'small'})
    public readonly size!: ElementUIComponentSize;

    @Prop({default: ''})
    public readonly label!: string;

    @Prop({default: 'file-o'})
    public readonly icon!: string;

    @Prop({default: 'left'})
    public readonly position!: string;

    @Prop({default: () => ({})})
    public readonly extraArgs!: any;

    @Prop({default: ''})
    public readonly toolTipTitle!: string;

    @Prop({default: []})
    public readonly optList!: any;

    @Prop({default: {}})
    public readonly rowData!: any;

    @Prop({default: 0})
    public readonly index!: number;

    @Prop({default: 0})
    public readonly numberRows!: number;

    @Prop({default: ''})
    public readonly url!: string;

    public blockDownload: boolean = false;

    public active: boolean = false;

    private forceDownload: boolean = true;

    @Emit('cleanFilter')
    public cleanFilter(): void {}

    public show() {
        this.active = !this.active;
    }

    public mouseOverListItem(event) {
        if (event.target.localName === 'li') {
            event.target.style.backgroundColor = 'lightgrey';
            event.target.children[0].children.forEach((child) => child.style.backgroundColor = 'lightgrey');
        }
    }

    public mouseOutListItem(event) {
        if (event.target.localName === 'li') {
            event.target.style.backgroundColor = 'white';
            event.target.children[0].children.forEach((child) => child.style.backgroundColor = 'white');
        }
    }

    public calculateOffset() {
        let retVal = 166;
        if (!this.rowData.comprovanteApp) {
            retVal -= 36;
        }
        if (!this.rowData.comprovanteForm) {
            retVal -= 36;
        }
        if (!this.rowData.comprovanteScan) {
            retVal -= 36;
        }
        return retVal !== 166 ? retVal - 36 : retVal;
    }

    public async download(params): Promise<void> {
        const data: any = typeof params.data === 'function' ? params.data() : params.data;
        if (this.blockDownload) {
            return;
        }

        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': params.type.accceptHeader,
            },
            responseType: params.type.responseType,
        };
        try {
            this.blockDownload = true;
            const response = params.method !== 'POST' ?
                await this.$axios.get(this.url, {params: data, ...config}) :
                await this.$axios.post(this.url, data, {params: this.extraArgs, ...config});

            this.active = false;
            this.blockDownload = false;

            if (response.status !== 200) {
                if (response.data && typeof response.data === 'string') {
                    this.$toast.warning(response.data);
                } else {
                    this.$toast.warning('Não foi possível realizar o download do comprovante.');
                }
                return;
            } else if (response.data.byteLength === 0) {
                this.$toast.warning('O arquivo não pôde ser encontrado.');
                return;
            }

            const blob = new Blob([response.data], {type: params.type.accceptHeader});
            const url = window.URL.createObjectURL(blob);
            if (this.forceDownload) {
                const body = document.body;
                const link = document.createElement('a');
                link.href = url;
                link.download = `${params.fileName}.${params.type.extention}`;
                body.appendChild(link);
                link.click();
                body.removeChild(link);
            } else {
                window.open(url);
            }
            window.URL.revokeObjectURL(url);
        } catch (error) {
            const msg = 'Erro ao realizar download.';
            this.$toast.error(msg);
        }
    }
}
