export class FreightTypes {

    public static NORMAL = new FreightTypes('NORMAL', 0, 'CT-e Normal');
    public static COMPLEMENTO = new FreightTypes('COMPLEMENTO_DE_VALORES', 1, 'CT-e de Complemento de Valores');
    public static ANULACAO = new FreightTypes('ANULACAO', 2, 'CT-e de Anulação');
    public static SUBSTITUTO = new FreightTypes('SUBSTITUTO', 3, 'CT-e Substituto');
    public static REENTREGA = new FreightTypes('REENTREGA', 4, 'CT-e Reentrega');
    public static DEVOLUCAO = new FreightTypes('DEVOLUCAO', 5, 'CT-e Devolução');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
    ) {}

    public toString() {
        return this._key;
    }

    get key(): string {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }
}

export class FreightTypesRequestLookup {

    public static lookup = [
        FreightTypes.NORMAL,
        FreightTypes.COMPLEMENTO,
        FreightTypes.ANULACAO,
        FreightTypes.SUBSTITUTO,
        FreightTypes.REENTREGA,
        FreightTypes.DEVOLUCAO,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = FreightTypes.NORMAL;

    public static getStatusByKey(value: string) {
        return this.lookup.find((v) => v.key === value) || this.default;
    }

    public static getStatusByValue(value: number) {
        return this.lookup.find((v) => v.value === value) || this.default;
    }

    private constructor() {}
}
