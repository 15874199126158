











import { Component, Vue } from 'vue-property-decorator';
import { EtapaRastreamento, EtapaRastreamentoLookup } from '@/enums/EtapaRastreamento';

@Component({
  name: 'SelectStageFreight',
})
export default class SelectStageFreight extends Vue {

  public value: string = '';

  public get options(): EtapaRastreamento[] {
    return EtapaRastreamentoLookup.lookup;
  }
}
